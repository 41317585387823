import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setScreenerData } from '../../../../redux/pages/screener/action';
import FilterActionBtns from './buttonAction';
import useStyles from '../../styles';
import { Button, Menu, MenuItem, IconButton, Typography } from '@material-ui/core';
import { Skeleton, Card } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@material-ui/icons/Close';
import FilterDropdown from './dropdown';
import FilterMultiSelect from './multiSelect';
import FilterRangeSelect from './rangeSelect';
import { getSelectedTabData } from '../../helper';

const FilterQuery = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { screenerData, selectedTabDataIndex, selectedTabData } = getSelectedTabData(useSelector);

  const updateScreenerDataAtIndex = (index, updatedTabData) => {
    const updatedScreenerData = screenerData.map((item, i) =>
      i === index ? updatedTabData : item
    );
    dispatch(setScreenerData(updatedScreenerData));
  };

  const handleAddFilter = (filterKey) => {
    const existingFilterConfig = selectedTabData.intialFilterConfig || {
      filters: {},
      selectedFilters: {}
    };

    const updatedFilters = {
      ...existingFilterConfig.selectedFilters,
      [filterKey]: {
        type: existingFilterConfig.filters[filterKey].type,
        selected: existingFilterConfig.filters[filterKey].selected,
        display_name: existingFilterConfig.filters[filterKey].display_name
      }
    };

    const updatedFilterConfig = {
      ...existingFilterConfig,
      selectedFilters: updatedFilters
    };

    const updatedTabData = {
      ...selectedTabData,
      intialFilterConfig: updatedFilterConfig
    };

    updateScreenerDataAtIndex(selectedTabDataIndex, updatedTabData);
  };

  const handleRemoveFilter = (filterKey) => {
    const existingFilterConfig = selectedTabData.intialFilterConfig || {
      filters: {},
      selectedFilters: {}
    };

    // Remove the filter from selectedFilters object
    const updatedFilters = { ...existingFilterConfig.selectedFilters };
    delete updatedFilters[filterKey];

    const updatedFilterConfig = {
      ...existingFilterConfig,
      selectedFilters: updatedFilters
    };

    const updatedTabData = {
      ...selectedTabData,
      intialFilterConfig: updatedFilterConfig
    };

    updateScreenerDataAtIndex(selectedTabDataIndex, updatedTabData);
  };

  const availableFilters = Object.keys(selectedTabData.intialFilterConfig?.filters || {}).filter(
    (filterKey) => !selectedTabData.intialFilterConfig?.selectedFilters[filterKey]
  );

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget); // Ensure it's the button itself
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className={classes.noScrollbarHorizantal}>
        <div>
          {Object.keys(selectedTabData.intialFilterConfig?.selectedFilters || {}).map(
            (filterKey, index) => (
              <div
                key={filterKey}
                style={{
                  display: 'flex'
                  // alignItems: 'center'
                }}
              >
                {index > 0 && (
                  <div
                    style={{
                      padding: '8px 5px'
                    }}
                  >
                    <div
                      style={{
                        height: '48px',
                        border: '2px solid #EAF1FE',
                        borderRadius: '4px',
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <Typography
                        style={{
                          padding: '4px  22px',
                          fontFamily: 'Satoshi',
                          fontWeight: 400,
                          fontSize: '16px',
                          color: '#171717'
                        }}
                      >
                        and
                      </Typography>
                    </div>
                  </div>
                )}
                <div
                  style={{
                    padding: '8px 5px'
                  }}
                >
                  <div
                    style={{
                      height: '48px',
                      width: '200px',
                      border: '2px solid #EAF1FE',
                      borderRadius: '4px',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <Typography
                      style={{
                        padding: '4px  12px',
                        fontFamily: 'Satoshi',
                        fontWeight: 400,
                        fontSize: '16px',
                        color: '#171717'
                      }}
                    >
                      {selectedTabData.intialFilterConfig?.filters[filterKey]?.display_name}
                    </Typography>
                  </div>
                </div>
                <div style={{ padding: '8px 5px', flexGrow: 1 }}>
                  <div
                    style={{
                      minHeight: '36px',
                      display: 'flex',
                      flexGrow: 1 // This will allow the dropdown/menu to take the available space
                      // alignItems: 'center'
                    }}
                  >
                    {selectedTabData.intialFilterConfig?.selectedFilters[filterKey].type ===
                      'dropdown' && (
                      <FilterDropdown
                        filterKey={filterKey}
                        currentTabData={selectedTabData}
                        currentTabDataIndex={selectedTabDataIndex}
                        screenerData={screenerData}
                      />
                    )}
                    {selectedTabData.intialFilterConfig?.selectedFilters[filterKey].type ===
                      'multi-select' && (
                      <FilterMultiSelect
                        filterKey={filterKey}
                        currentTabData={selectedTabData}
                        currentTabDataIndex={selectedTabDataIndex}
                        screenerData={screenerData}
                      />
                    )}
                    {selectedTabData.intialFilterConfig?.selectedFilters[filterKey].type ===
                      'range' && (
                      <FilterRangeSelect
                        filterKey={filterKey}
                        currentTabData={selectedTabData}
                        currentTabDataIndex={selectedTabDataIndex}
                        screenerData={screenerData}
                      />
                    )}
                  </div>
                </div>
                <div style={{ padding: '8px 5px' }}>
                  <div
                    style={{
                      height: '36px',
                      width: '36px',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <IconButton onClick={() => handleRemoveFilter(filterKey)}>
                      <CloseIcon fontSize="16px" />
                    </IconButton>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
        {selectedTabData?.loadingFilterData && (
          <div style={{ padding: '20px 0px' }}>
            {Array.from({ length: 2 }).map((_, index) => (
              <div key={index}>
                <Card
                  sx={{
                    maxWidth: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: '#FAFAFA',
                    borderRadius: '8px',
                    border: '1px solid #E8E8E8'
                  }}
                  elevation={0}
                >
                  <Skeleton
                    animation="wave"
                    height={10}
                    width="20%"
                    style={{ marginBottom: 10, marginLeft: 20, marginTop: 10 }}
                  />
                  <Skeleton
                    animation="wave"
                    height={10}
                    width="90%"
                    style={{ marginBottom: 10, marginLeft: 20, marginRight: 6 }}
                  />
                  <Skeleton
                    animation="wave"
                    height={10}
                    width="70%"
                    style={{ marginBottom: 10, marginLeft: 20, marginRight: 6 }}
                  />
                </Card>
                <br />
              </div>
            ))}
          </div>
        )}
        {!selectedTabData?.loadingFilterData && (
          <>
            {availableFilters.length > 0 && (
              <div style={{ padding: '6px 2px' }}>
                <Button
                  onClick={handleClick}
                  style={{
                    fontFamily: 'Satoshi',
                    fontWeight: 500,
                    fontSize: '18px',
                    color: '#171717',
                    display: 'flex'
                  }}
                  className={classes.addFiltersBtn}
                >
                  <AddIcon style={{ fontSize: '16px' }} />
                  <Typography
                    style={{
                      fontFamily: 'Satoshi',
                      fontWeight: 500,
                      fontSize: '12px',
                      color: '#171717',
                      padding: '6px 4px'
                    }}
                  >
                    Add Filter
                    {/* <AddIcon /> Add Filter */}
                  </Typography>
                </Button>
                <Menu
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  getContentAnchorEl={null} // Ensures it positions properly
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  {availableFilters.map((filterKey) => (
                    <MenuItem
                      key={filterKey}
                      onClick={() => {
                        handleAddFilter(filterKey);
                        handleClose();
                      }}
                      style={{
                        fontFamily: 'Satoshi',
                        fontWeight: 400,
                        fontSize: '16px',
                        color: '#171717'
                      }}
                    >
                      {selectedTabData.intialFilterConfig?.filters[filterKey]?.display_name}
                    </MenuItem>
                  ))}
                </Menu>
              </div>
            )}
            {availableFilters.length === 0 && (
              <div style={{ padding: '6px 2px' }}>
                <Button
                  disabled={true}
                  style={{
                    fontFamily: 'Satoshi',
                    opacity: '0.5',
                    fontWeight: 500,
                    fontSize: '18px',
                    color: '#171717',
                    display: 'flex'
                  }}
                  className={classes.addFiltersBtn}
                >
                  <Typography
                    style={{
                      fontFamily: 'Satoshi',
                      fontWeight: 500,
                      fontSize: '12px',
                      color: '#171717',
                      padding: '6px 4px'
                    }}
                  >
                    All Filters Added
                  </Typography>
                </Button>
              </div>
            )}
          </>
        )}
      </div>
      <FilterActionBtns />
    </>
  );
};

export default FilterQuery;
