import React, { useState, useRef, useCallback } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { Checkbox, TextField, makeStyles } from '@material-ui/core';
import { ReactComponent as SearchIcon } from '../../../components/icons/SearchIcon.svg';
import { getDisplayTextFromMap, getNestedValue } from '../../utils/utils';
import EntityTypeFilterTabs from '../entitySearch/entityTypeFilterTabsTheme';
// import { IconButton, Typography } from '@mui/material';
import { Typography, IconButton } from '@material-ui/core';

import StaticChip from '../staticChip';
import { ReactComponent as DeleteBinIcon } from '../../icons/DeleteBinIcon.svg';
import { ReactComponent as EditIcon } from '../../icons/edit.svg';
// for single select
// const [selectedItems, setSelectedItems] = useState(null);

// for multi select
// const [selectedItems, setSelectedItems] = useState([]);

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#FFFFFF',
      '& fieldset': {
        border: '1px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      },
      '&:hover fieldset': {
        border: '1px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      }
    },
    '& .MuiInputBase-input::placeholder': {
      color: '#000000',
      opacity: 1
    },
    '& .MuiInputBase-input': {
      // height: '10px !important',
      fontFamily: 'Satoshi !important'
    }
  },
  searchIcon: {
    marginRight: '4px',
    '&:hover': {
      backgroundColor: 'transparent'
    },
    color: '#80a6ff'
  },
  inputLabel: {
    fontFamily: 'Satoshi !important',
    color: '#000000 !important',
    opacity: 0.6
  },
  optionTypo: {
    fontSize: '14px',
    fontWeight: 400,
    margin: '4px 0px'
  },
  actionIcons: {
    display: 'none',
    flex: '0 0 20%',
    justifyContent: 'flex-end',
    gap: '8px'
  },
  listboxItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    'padding-top': '6px',
    'padding-bottom': '6px',
    '&:hover $actionIcons': {
      display: 'flex'
    }
  },
  fCMainDiv: {
    'padding-left': '16px',
    'padding-right': '16px'
  },
  firstChild: {
    flex: '0 0 80%',
    overflow: 'hidden'
  }
}));

const emptyFn = () => {};

const AutocompleteComponent = ({
  data,
  hasMore,
  setInputValue,
  onInputChange = emptyFn,
  setPage,
  selectedItems,
  setSelectedItems,
  property = { key: 'key', value: 'value', type: 'entity_type' },
  isSingleSelect = false,
  searchIcon = true,
  label = 'Search Companies',
  placeholder = 'Type to search',
  disabled = false,
  loading = false,
  showChips = false,
  tabOptions = [],
  chipsData = [],
  selectedTab = '',
  onSelectedTabOption = emptyFn,
  onEditTheme = emptyFn,
  onDeleteTheme = emptyFn
}) => {
  const classes = useStyles();
  const { key, value, type } = property;
  const [open, setOpen] = useState(false);
  const scrollTimeout = useRef(null);

  const handleOptionToggle = (e, option) => {
    if (isSingleSelect) {
      setSelectedItems({ [key]: option[key], [value]: option[value], [type]: option[type] });
      setOpen(false);
    } else {
      const currentIndex = selectedItems.findIndex((item) => item[key] === option[key]);
      let newSelectedItems = [];

      if (currentIndex === -1) {
        newSelectedItems = [
          ...selectedItems,
          { [key]: option[key], [value]: option[value], [type]: option[type] }
        ];
      } else {
        newSelectedItems = selectedItems.filter((item, index) => index !== currentIndex);
      }

      setSelectedItems(newSelectedItems);
    }
  };

  const handleScroll = useCallback(
    (event) => {
      if (scrollTimeout.current) clearTimeout(scrollTimeout.current);

      scrollTimeout.current = setTimeout(() => {
        // const target = event.currentTarget;
        const { scrollTop, scrollHeight, clientHeight } = event.target;
        // const isBottom = scrollHeight - scrollTop <= clientHeight + 10;
        const isBottom = scrollHeight - scrollTop - (data?.length || 0) * 4 <= clientHeight + 10;
        if (isBottom && hasMore) {
          setPage((prev) => prev + 1);
        }
      }, 300);
    },
    [data, hasMore, setPage]
  );

  const onSelectEntity = (value) => {
    setInputValue(value?.entity_name || '');
    setSelectedItems(value);
    // setOpen(false);
  };

  return (
    <Autocomplete
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      multiple={!isSingleSelect}
      className={classes.root}
      options={data}
      getOptionLabel={(option) => option[value] || ''}
      value={selectedItems}
      disabled={disabled}
      onChange={(event, newValue) => {
        if (isSingleSelect) {
          setSelectedItems(newValue);
        } else {
          setSelectedItems(newValue);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            startAdornment: searchIcon && (
              <>
                <SearchIcon className={classes.searchIcon} />
                {params.InputProps.startAdornment}
              </>
            )
          }}
          InputLabelProps={{
            classes: {
              root: classes.inputLabel
            }
          }}
        />
      )}
      // loading={data && data.length === 0 && loading}
      loading={loading}
      renderOption={(props, option, { selected }) => (
        <>
          <div {...props} className={classes.listboxItem}>
            <div className={classes.fCMainDiv}>
              <div
                className={classes.firstChild}
                onClick={(event) => handleOptionToggle(event, option)}
              >
                <Typography className={classes.optionTypo}>
                  {!isSingleSelect && (
                    <Checkbox checked={selected} style={{ color: '#004CFF', marginRight: 8 }} />
                  )}
                  {getNestedValue(option, 'entity_name')}
                </Typography>
              </div>
            </div>
            <StaticChip displayText={getDisplayTextFromMap(option, 'type', [])} />
            {showChips && selectedTab === 'user_defined' && (
              <div className={classes.actionIcons}>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    onEditTheme(option);
                  }}
                  size="small"
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    onDeleteTheme(option);
                  }}
                  size="small"
                >
                  <DeleteBinIcon />
                </IconButton>
              </div>
            )}
          </div>
          {/* {showChips ? (
            
          ) : (
            <li
              {...props}
              onClick={(event) => handleOptionToggle(event, option)}
              style={{ backgroundColor: selected ? '#fafafa' : 'transparent' }}
            >
              {!isSingleSelect && (
                <Checkbox checked={selected} style={{ color: '#004CFF', marginRight: 8 }} />
              )}
              {option[value]}
            </li>
          )} */}
        </>
      )}
      onInputChange={(event, newValue) => {
        setInputValue(newValue);
        onInputChange(event, newValue);
      }}
      isOptionEqualToValue={(option, value) => option[key] === value[key]}
      ListboxProps={{
        onMouseDown: (event) => {
          event.preventDefault();
        },
        onScroll: handleScroll,
        tabsOptions: tabOptions,
        setFilterTab: onSelectedTabOption,
        selectedTab,
        showChips,
        chipsData,
        onSelectEntity
      }}
      ListboxComponent={EntityTypeFilterTabs}
    />
  );
};

export default AutocompleteComponent;
