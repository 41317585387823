import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { useNavigate } from 'react-router-dom';
import useStyles from './styles';
import ScreenerTab from './screenerTab';
import ScreenerTable from './common/table';
import ScreenerMainSearch from './common/search';
import { useSelector, useDispatch } from 'react-redux';
import LoadingPopover from '../shared/loadingPopover/loadingPopover';
import ErrorToast from '../atoms/errorToast';
import { getFilterConfig } from './services';
import { getSelectedTabData } from './helper';
import { setScreenerData } from '../../redux/pages/screener/action';

const ScreenerV1 = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [errorHigeShowMessage, seterrorHigeShowMessage] = useState('');
  const enableScreener = process.env.REACT_APP_ENABLE_SCREENER === 'true' ?? false;
  const { screenerData, selectedTabDataIndex, selectedTabData } = getSelectedTabData(useSelector);
  const selectedScreenerTab = useSelector((state) => state.screenerReducer.selectedScreenerTab);
  const selectedTabFilterType = useSelector((state) => state.screenerReducer.selectedTabFilterType);

  const errorMessage = useSelector((state) => state.screenerReducer.errorMessage);

  const { tableData = {}, isLoading } = selectedTabData || {};
  const { items = [] } = tableData || {};

  const navigate = useNavigate();

  useEffect(() => {
    if (!enableScreener) {
      navigate('/home');
    }
  }, []);

  const showErrorMessage = (msg) => {
    seterrorHigeShowMessage(msg, errorHigeShowMessage, 3000);
  };

  const handleAddFilter = (filterKey, existingFilterConfig) => {
    return {
      ...existingFilterConfig,
      selectedFilters: {
        ...existingFilterConfig.selectedFilters,
        [filterKey]: {
          type: existingFilterConfig.filters[filterKey].type,
          selected: existingFilterConfig.filters[filterKey].selected,
          display_name: existingFilterConfig.filters[filterKey].display_name
        }
      }
    };
  };

  const getFilterConfigBySelectedTab = async () => {
    let updatedTabData = { ...selectedTabData };
    updatedTabData = {
      ...updatedTabData,
      loadingFilterData: true
    };
    const updatedScreenerData = screenerData.map((item, i) =>
      i === selectedTabDataIndex ? updatedTabData : item
    );

    dispatch(setScreenerData(updatedScreenerData));
    const filterConfig = await getFilterConfig(selectedTabFilterType);
    if (filterConfig !== null) {
      // If there's no initialFilterConfig, set it first
      let updatedTabData = { ...selectedTabData };

      if (selectedTabData.filterType === 'filter' && !selectedTabData.intialFilterConfig) {
        updatedTabData = {
          ...updatedTabData,
          intialFilterConfig: filterConfig
        };
      }

      // Now handle the filters if initialFilterConfig exists
      if (
        updatedTabData.intialFilterConfig &&
        updatedTabData.intialFilterConfig.selectedFilters &&
        Object.keys(updatedTabData.intialFilterConfig.selectedFilters).length === 0
      ) {
        let updatedFilterConfig = { ...updatedTabData.intialFilterConfig };

        Object.entries(updatedTabData.intialFilterConfig.filters).forEach(([key, value]) => {
          if (value.default_opened) {
            updatedFilterConfig = handleAddFilter(key, updatedFilterConfig);
          }
        });

        updatedTabData = {
          ...updatedTabData,
          intialFilterConfig: updatedFilterConfig,
          loadingFilterData: false
        };
      }

      // Update the screener data with the combined data
      const updatedScreenerData = screenerData.map((item, i) =>
        i === selectedTabDataIndex ? updatedTabData : item
      );

      dispatch(setScreenerData(updatedScreenerData));
    } else {
      let updatedTabData = { ...selectedTabData };
      updatedTabData = {
        ...updatedTabData,
        loadingFilterData: false
      };
      const updatedScreenerData = screenerData.map((item, i) =>
        i === selectedTabDataIndex ? updatedTabData : item
      );

      dispatch(setScreenerData(updatedScreenerData));
      showErrorMessage('Error loading screener filter data, please try again later.');
    }
  };

  useEffect(() => {
    if (selectedScreenerTab === 'filter' && !selectedTabData?.intialFilterConfig) {
      getFilterConfigBySelectedTab();
    }
  }, [selectedScreenerTab, selectedTabFilterType]);

  const showTable = (items && items.length > 0) || isLoading;
  return (
    <>
      <ErrorToast errorMessage={errorMessage} />
      <ErrorToast errorMessage={errorHigeShowMessage} />
      <LoadingPopover open={selectedTabData?.isLoading} />
      <div>
        <div style={{ backgroundColor: '#F7F9FD' }}>
          <div style={{ maxWidth: '1440px', margin: '0 auto' }}>
            <div style={{ padding: '10px 40px 16px 40px' }}>
              <Typography className={classes.screenerTxt}>
                <span
                  style={{
                    backgroundImage: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent'
                  }}
                >
                  Screening
                </span>
              </Typography>
              <ScreenerTab />
              <ScreenerMainSearch />
            </div>
          </div>
        </div>
        <div style={{ maxWidth: '1440px', margin: '0 auto' }}>{showTable && <ScreenerTable />}</div>
      </div>
      {!showTable && <ScreenerTable />}
    </>
  );
};

export default ScreenerV1;
