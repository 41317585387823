import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { AuthContext, AuthStatus } from '../../auth/contexts/authContext';
import CustomRoutes from '../../../routes/route';
import { Grid } from '@material-ui/core';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as MessageIcon } from '../../icons/sideBar/Message.svg';
import { ReactComponent as DashBoardNewIcon } from '../../icons/sideBar/dashboard-new.svg';
import { ReactComponent as ReportIcon } from '../../icons/sideBar/Report.svg';
import { ReactComponent as ThemeIcon } from '../../icons/sideBar/theme-report.svg';
import { ReactComponent as DocSearchIcon } from '../../icons/sideBar/docs.svg';
import { ReactComponent as ExploreIcon } from '../../icons/sideBar/explore_company.svg';
import { ReactComponent as GraphIcon } from '../../icons/sideBar/tracker.svg';
import { ReactComponent as MainLibIcon } from '../../icons/sideBar/main_Lib.svg';
import { ReactComponent as LibIcon } from '../../icons/sideBar/lib.svg';
import { ReactComponent as ScreenerIcon } from '../../icons/sideBar/screener.svg';

import { ReactComponent as CompReportIcon } from '../../icons/sideBar/comp-report.svg';
import { ReactComponent as DocExpIcon } from '../../icons/sideBar/docs-exp.svg';
import { ReactComponent as DocComIcon } from '../../icons/sideBar/docs-com.svg';

import { ReactComponent as DocLibIcon } from '../../icons/sideBar/doc-Lib.svg';

import { ReactComponent as MessageIconGradient } from '../../icons/sideBar/Gradient/Message.svg';
import { ReactComponent as DashBoardNewIconGradient } from '../../icons/sideBar/Gradient/dashboard-new.svg';
import { ReactComponent as ReportIconGradient } from '../../icons/sideBar/Gradient/Report.svg';
import { ReactComponent as ThemeIconGradient } from '../../icons/sideBar/Gradient/theme-report.svg';
import { ReactComponent as DocSearchIconGradient } from '../../icons/sideBar/Gradient/docs.svg';
import { ReactComponent as ExploreIconGradient } from '../../icons/sideBar/Gradient/explore_company.svg';
import { ReactComponent as GraphIconGradient } from '../../icons/sideBar/Gradient/tracker.svg';
import { ReactComponent as MainLibIconGradient } from '../../icons/sideBar/Gradient/main_Lib.svg';
import { ReactComponent as LibIconGradient } from '../../icons/sideBar/Gradient/lib.svg';

import { ReactComponent as ScreenerIconGradient } from '../../icons/sideBar/Gradient/screener.svg';

import { ReactComponent as CompReportIconGradient } from '../../icons/sideBar/Gradient/comp-report.svg';
import { ReactComponent as DocExpIconGradient } from '../../icons/sideBar/Gradient/docs-exp.svg';
import { ReactComponent as DocComIconGradient } from '../../icons/sideBar/Gradient/docs-com.svg';

import { ReactComponent as DocLibIconGradient } from '../../icons/sideBar/Gradient/doc-Lib.svg';

import { useDispatch } from 'react-redux';
import { setTearsheetUrl } from '../action';

const useStyles = makeStyles(() => ({
  // Parent menu item container
  boxContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
    background: '#F7F9FD',
    borderRight: '1px solid #F0F0F0',
    cursor: 'pointer',
    position: 'relative',
    '&:hover': {
      background: '#EAF1FE'
    },
    '&.active': {
      background:
        'var(--Gradients-Light-Gradients-blue-gradient-40, linear-gradient(270deg, rgba(205, 241, 243, 0.40) 0%, rgba(205, 219, 255, 0.40) 100%))',
      '& $boxText': {
        background: 'linear-gradient(to right, #004CFF, #06BCC1)',
        WebkitBackgroundClip: 'text',
        backgroundClip: 'text',
        color: 'transparent'
      }
    }
  },
  // Content inside each menu item
  boxContent: {
    textAlign: 'center'
  },
  boxImage: {
    width: '24px',
    height: '24px'
  },
  chboxImage: {
    width: '20px',
    height: '20px'
  },
  boxText: {
    margin: '0',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400
  },
  // Container for child menus (shown on hover)
  childContainer: {
    position: 'absolute',
    top: 0,
    left: '100%',
    background: '#F7F9FD',
    border: '1px solid #F0F0F0',
    zIndex: 10,
    minWidth: '150px',
    '&:hover': {
      background: '#EAF1FE'
    }
  },
  // Style for each child menu item
  childItem: {
    padding: '10px',
    cursor: 'pointer',
    // textAlign: 'center',
    background: '#F7F9FD',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      background: '#EAF1FE'
    },
    '&.active': {
      background:
        'var(--Gradients-Light-Gradients-blue-gradient-40, linear-gradient(270deg, rgba(205, 241, 243, 0.40) 0%, rgba(205, 219, 255, 0.40) 100%))',
      '& $boxText': {
        background: 'linear-gradient(to right, #004CFF, #06BCC1)',
        WebkitBackgroundClip: 'text',
        backgroundClip: 'text',
        color: 'transparent'
      }
    }
  },
  childIconBox: {
    width: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  childTextBox: {
    flex: 1,
    marginLeft: '8px',
    display: 'flex',
    alignItems: 'center'
  },
  mdLt10: {
    'margin-left': '10px'
  }
}));

// Updated JSON with a child menus example
const Menus = [
  {
    text: 'Ask Byz',
    icon: MessageIcon,
    gradientIcon: MessageIconGradient,
    route: 'askbyz',
    matchedRoute: 'askbyz'
  },
  {
    text: 'Dashboard',
    icon: DashBoardNewIcon,
    gradientIcon: DashBoardNewIconGradient,
    route: 'dashboard',
    matchedRoute: 'dashboard'
  },
  {
    text: 'Screener',
    icon: ScreenerIcon,
    gradientIcon: ScreenerIconGradient,
    route: 'screener',
    matchedRoute: 'screener'
  },
  {
    text: 'Reports',
    icon: ReportIcon,
    gradientIcon: ReportIconGradient,
    matchedRoute: 'report',
    children: [
      {
        text: 'Company Reports',
        icon: CompReportIcon,
        gradientIcon: CompReportIconGradient,
        route: 'report?entity_type=company',
        matchedRoute: 'report'
      },
      {
        text: 'Theme Reports',
        icon: ThemeIcon,
        gradientIcon: ThemeIconGradient,
        route: 'report?entity_type=theme',
        matchedRoute: 'report'
      }
    ]
  },
  {
    text: 'Documents',
    icon: DocSearchIcon,
    gradientIcon: DocSearchIconGradient,
    matchedRoute: 'doc-explorer',
    children: [
      {
        text: 'Document Explorer',
        icon: DocExpIcon,
        route: 'doc-explorer',
        gradientIcon: DocExpIconGradient,
        matchedRoute: 'doc-explorer'
      },
      {
        text: 'Document Comparison',
        icon: DocComIcon,
        gradientIcon: DocComIconGradient,
        route: 'doc-comparison',
        matchedRoute: 'doc-comparison'
      }
    ]
  },
  {
    text: 'Explore Companies',
    icon: ExploreIcon,
    gradientIcon: ExploreIconGradient,
    route: 'explore-all-companies',
    matchedRoute: 'explore-all-companies'
  },
  {
    text: 'Tracker',
    icon: GraphIcon,
    gradientIcon: GraphIconGradient,
    route: 'tracker',
    matchedRoute: 'tracker'
  },
  {
    text: 'Library',
    icon: MainLibIcon,
    gradientIcon: MainLibIconGradient,
    matchedRoute: 'my-templates',
    children: [
      {
        text: 'Document Library',
        icon: DocLibIcon,
        gradientIcon: DocLibIconGradient,
        route: 'document-upload/manage',
        matchedRoute: 'document-upload/manage'
      },
      {
        text: 'Template Library',
        icon: LibIcon,
        gradientIcon: LibIconGradient,
        route: 'my-templates',
        matchedRoute: 'my-templates'
      }
    ]
  }
];

const BodyComponent = ({ prevRoute }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { authStatus } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const isSignedIn = authStatus === AuthStatus.SignedIn && location.pathname !== '/signin';
  const fullWidth = isSignedIn ? 11 : 12;
  const [hoveredMenu, setHoveredMenu] = useState(null);

  const updateTearsheetUrl = (url) => {
    dispatch(setTearsheetUrl(url));
  };

  // Helper: determine if a (parent or child) menu is active based on pathname and query params
  const isMenuActive = (menu) => {
    const searchParams = new URLSearchParams(location.search);
    const entityType = searchParams.get('entity_type');

    // If there are child menus, consider active if any child matches
    if (menu.children) {
      return (
        //location.pathname.includes(menu.matchedRoute) &&
        menu.children.some((child) => {
          // const childExpected = new URLSearchParams(child.route).get('entity_type');
          // return childExpected ? entityType === childExpected : true;
          return location.pathname.includes(child.matchedRoute);
        })
      );
    } else {
      const expected = new URLSearchParams(menu.route).get('entity_type');
      return (
        location.pathname.includes(menu.matchedRoute) && (expected ? entityType === expected : true)
      );
    }
  };

  // Helper: check if a child menu is active
  const isChildActive = (child) => {
    if (child.matchedRoute === 'report') {
      return (location.pathname + location.search).includes(child.route);
    }
    return location.pathname.includes(child.matchedRoute);
  };

  return (
    <Grid container>
      {isSignedIn && (
        <Grid
          item
          sm={1}
          xs={1}
          style={{ background: '#F7F9FD', borderRight: '1px solid #F0F0F0', position: 'relative' }}
        >
          {Menus.map((menu) => {
            const menuKey = menu.route || menu.text;
            const parentActive = isMenuActive(menu);
            // Determine which Icon to use (gradient if active)
            const ParentIcon = parentActive ? menu.gradientIcon : menu.icon;
            const haveChildren = menu.children && menu.children.length > 0;
            return (
              // Wrap each menu item in a relative container to allow absolutely positioned child menus
              <div
                key={menuKey}
                onMouseEnter={() => menu.children && setHoveredMenu(menuKey)}
                onMouseLeave={() => menu.children && setHoveredMenu(null)}
                style={{ position: 'relative' }}
              >
                <div
                  className={`${classes.boxContainer} ${parentActive ? 'active' : ''}`}
                  onClick={() => {
                    // If this is a report menu, update tear sheet URL accordingly
                    if (!haveChildren && menu.matchedRoute === 'report') {
                      updateTearsheetUrl(menu.route.split('report').pop());
                    }
                    if (!haveChildren) navigate(`/${menu.route}`);
                  }}
                >
                  <div className={classes.boxContent}>
                    <ParentIcon className={classes.boxImage} />
                    <p className={classes.boxText}>{menu.text}</p>
                  </div>
                </div>

                {/* If menu has children and is hovered, show the child menu container */}
                {haveChildren && hoveredMenu === menuKey && (
                  <div className={classes.childContainer}>
                    {menu.children.map((child) => {
                      const childActive = isChildActive(child);
                      const ChildIcon = childActive ? child.gradientIcon : child.icon;
                      return (
                        <div
                          key={child.text}
                          className={`${classes.childItem} ${childActive ? 'active' : ''}`}
                          onClick={(e) => {
                            // Prevent the parent's onClick from triggering
                            e.stopPropagation();
                            if (child.matchedRoute === 'report') {
                              updateTearsheetUrl(child.route.split('report').pop());
                            }
                            navigate(`/${child.route}`);
                          }}
                        >
                          <div className={classes.childIconBox}>
                            <ChildIcon className={classes.chboxImage} />
                          </div>
                          <div className={classes.childTextBox}>
                            <p className={classes.boxText}>{child.text}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          })}
        </Grid>
      )}
      <Grid item sm={fullWidth} xs={fullWidth}>
        <CustomRoutes prevRoute={prevRoute} />
      </Grid>
    </Grid>
  );
};

export default BodyComponent;
