import Company from './searchable/company';
import Theme from './searchable/theme';

const CompanyThemeSearch = (props) => {
  const { activeEntityType } = props;

  return <>{activeEntityType === 'company' ? <Company {...props} /> : <Theme {...props} />}</>;
};

export default CompanyThemeSearch;
