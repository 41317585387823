import { combineReducers } from 'redux';
import tokenReducer from '../components/shared/reducer';
import {
  screenerReducer,
  docExplorerReducer,
  docCompareReducer,
  companyReducer,
  themeReducer
} from './pages';

const rootReducer = combineReducers({
  tokenReducer,
  screenerReducer,
  docExplorerReducer,
  docCompareReducer,
  companyReducer,
  themeReducer
});

export default rootReducer;
