import React from 'react';
import { Tabs, Tab } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
// import { setScreenerData, setSelectedTabFilterType } from '../../../../redux/pages/screener/action';
import { setSelectedTabFilterType } from '../../../../redux/pages/screener/action';
import TextQuery from './textQuery';
import FilterQuery from './filterQuery';

const FilterBox = () => {
  const dispatch = useDispatch();
  const screenerFilterTypes = useSelector((state) => state.screenerReducer.screenerFilterTypes);
  const selectedScreenerTab = useSelector((state) => state.screenerReducer.selectedScreenerTab);

  const handleTabChange = (event, newValue) => {
    dispatch(setSelectedTabFilterType(newValue));
  };

  const selectedTabFilterType = useSelector((state) => state.screenerReducer.selectedTabFilterType);

  return (
    <div
      style={{
        // minHeight: '300px',
        background: '#FFFFFF',
        border: '1px solid #E7E7E7',
        borderRadius: '8px',
        padding: '0px 16px 16px 16px'
      }}
    >
      {selectedScreenerTab === 'filter' && (
        <Tabs
          value={selectedTabFilterType}
          onChange={handleTabChange}
          TabIndicatorProps={{
            sx: {
              bgcolor:
                selectedTabFilterType ===
                screenerFilterTypes.find((filter) => filter.key === selectedTabFilterType)?.key
                  ? '#004CFF !important'
                  : '#767676 !important',
              height: '1px'
            }
          }}
          style={{ padding: '5px 10px' }}
        >
          {screenerFilterTypes.map((filter) => (
            <Tab
              key={filter.key}
              label={filter.value}
              value={filter.key}
              sx={{
                textTransform: 'none',
                fontFamily: 'Satoshi',
                fontWeight: 'bold',
                fontSize: '14px',
                minWidth: '60px',
                padding: '0px 8px 0px 8px',
                color:
                  selectedTabFilterType === filter.key ? '#004CFF !important' : '#767676 !important'
              }}
            />
          ))}
        </Tabs>
      )}
      {selectedScreenerTab === 'custom' && <TextQuery />}
      {selectedScreenerTab === 'filter' && <FilterQuery />}
    </div>
  );
};

export default FilterBox;
