import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { List, ListItemButton, Typography, Collapse } from '@mui/material';
import { setScreenerData } from '../../../redux/pages/screener/action';
import { makeStyles } from '@material-ui/core';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const useStyles = makeStyles(() => ({
  accordionSummary: {
    display: 'flex',
    padding: '0px',
    alignItems: 'center'
  },
  liItemBtSelected: {
    background:
      'var(--Gradients-Gradient4, linear-gradient(270deg, #CDF1F3 0%, #CDDBFF 100%)) !important',
    padding: '5px 10px !important',
    minHeight: '40px'
  },
  queriesHeading: {
    fontFamily: 'Satoshi !important',
    color: '#373737',
    fontSize: '16px !important',
    fontWeight: '700 !important',
    padding: '10px 16px'
  },
  liItemBtUnSelected: {
    padding: '5px 10px !important',
    minHeight: '40px'
  },
  liItemSelectedTxt: {
    fontFamily: 'Satoshi !important',
    color: '#373737',
    fontSize: '14px !important',
    fontWeight: '700 !important'
  },
  liItemUnSelectedTxt: {
    fontFamily: 'Satoshi !important',
    color: '#373737',
    fontSize: '14px !important',
    fontWeight: '400 !important'
  },
  liItemTxt: {
    paddingLeft: '15px',
    fontFamily: 'Satoshi !important',
    color: '#373737',
    fontSize: '14px !important',
    fontWeight: '400 !important'
  },
  expandIconOpen: {
    transform: 'rotate(0deg)'
  },
  expandIconClosed: {
    transform: 'rotate(-90deg)'
  },
  expandIcon: {
    transition: 'transform 0.3s ease-in-out!important',
    fontSize: '16px!important',
    padding: '0px 0',
    paddingRight: '5px'
  }
}));

const DefinedScreener = () => {
  const selectedTabFilterType = useSelector((state) => state.screenerReducer.selectedTabFilterType);
  const selectedScreenerTab = useSelector((state) => state.screenerReducer.selectedScreenerTab);
  const screenerData = useSelector((state) => state.screenerReducer.screenerData);
  const dispatch = useDispatch();
  const classes = useStyles();

  const queryData =
    selectedScreenerTab === 'company'
      ? [
          {
            queryName: 'Simple',
            queryId: 'uuid1',
            queries: [
              // { query: 'Give me list of all lending companies' },
              // { query: 'Give me list of all companies started after 2023' },
              // { query: 'Give me list of companies where employee count is greater than 1000' },
              // { query: 'Give me list of Companies which have zepto as the competitor.' },
              // { query: 'Give me list of companies with founders from India' },
              // { query: 'Give me list of companies with female founders' }
              { query: 'Give me a list of all companies started after 2023.' },
              { query: 'Give me a list of companies with more than 1,000 employees.' },
              { query: 'Give me a list of companies with female founders.' }
            ]
          },
          {
            queryName: 'Complex',
            queryId: 'uuid2',
            queries: [
              // { query: 'Give me list of companies with both rapido and Uber as an competitor' },
              // { query: 'Give a list of Indian Series A companies in the fintech sector.' },
              // {
              //   query: 'Give me the list of companies with revenue in FY21 between 10 and 15 crores'
              // },
              // {
              //   query:
              //     'Give me list of companies which with valuation more than 6 million dollars in either 2024 or 2023'
              // },
              // { query: 'Give me list of all companies who are working in beauty cosmetics' },
              // {
              //   query:
              //     'Give me all beauty & personal care companies who have last raised Series A round'
              // }
              { query: 'Give me a list of Indian Series A companies in the retail sector.' },
              {
                query:
                  'Give me a list of companies with revenue between ₹10 crore and ₹15 crore in FY21.'
              },
              { query: 'Give me a list of companies with a valuation of more than $15 million.' },
              {
                query:
                  'Give me a list of all companies working in the beauty and cosmetics industry.'
              },
              { query: 'List all companies that have more than 20 angel investors.' }
            ]
          },
          {
            queryName: 'Competitors', // 'Investor',
            queryId: 'uuid3',
            queries: [
              // { query: 'List all companies that have less than 10 Angel investors' },
              // {
              //   query:
              //     'List all B2B companies operating in the Ed-Tech sector, having more than 7 angel investors'
              // },
              // {
              //   query:
              //     'Give me list of companies where Kunal shah has invested and their revenue is between 10 crores to 100 crores'
              // }
              {
                query: 'Give me a list of companies that have both Rapido and Uber as competitors.'
              },
              { query: 'Give me a list of companies that have Zepto as a competitor.' }
            ]
          }
        ]
      : [
          {
            queryName: 'Demography',
            queryId: 'uuid4',
            queries: [
              // { query: 'Give me list of people who are presently founders and are from India' },
              // { query: 'Give me list of founders who are from United States of America' },
              // {
              //   query:
              //     'Give me list of people who are presently founders and are from bangalore or Bengaluru'
              // },
              // { query: 'Give me list of founders who are from California' },
              // { query: 'Give me list of founders who are female' }
              { query: 'Give me a list of people who are presently founders and are from India.' },
              { query: 'Give me a list of founders who are from the United States of America.' },
              {
                query:
                  'Give me a list of people who are presently founders and are from Bangalore or Bengaluru.'
              }
            ]
          },
          {
            queryName: 'Education',
            queryId: 'uuid5',
            queries: [
              // { query: 'Give me all co-founders from fintech industry who have studied from IIT' },
              // {
              //   query: 'Give me names of Chief Financial Officers or CFOs who are from IIM or ISB'
              // },
              // {
              //   query:
              //     'Give me names of founders who have studied from Institute of Chartered Accountants of India'
              // }
              {
                query:
                  'Give me the names of Chief Financial Officers (CFOs) who have studied at IIM or ISB.'
              },
              {
                query:
                  'Give me the names of founders who have studied at the Institute of Chartered Accountants of India.'
              }
            ]
          },
          {
            queryName: 'Work History', // 'Previous History',
            queryId: 'uuid6',
            queries: [
              // { query: 'Give me all founders who have previously worked in Mckinsey & Co.' },
              // {
              //   query:
              //     'Give me a list of people in the united states of america who have held the title of CTO'
              // },
              // {
              //   query:
              //     'Give me list of people from Bangalore or Bengaluru who presently partner at either Mckinsey or Bain presently'
              // }
              {
                query:
                  'Give me a list of all founders who have previously worked at Boston Consulting Group.'
              },
              {
                query:
                  'Who are the key people at Fireside Ventures who look at Beauty and Fashion companies.'
              }
            ]
          },
          {
            queryName: 'Recent Founders',
            queryId: 'uuid7',
            queries: [
              // {
              //   query:
              //     'Give me list of people who are presently founders and became founders in 2023'
              // },
              // {
              //   query:
              //     'Give me list of people who are presently founders and have held the position of founder more than once'
              // },
              // { query: 'Give me list of people whose present company is stealth' },
              // {
              //   query:
              //     'Give me list of people whose present company is stealth but have previously worked in Mckinsey'
              // }
              {
                query:
                  'Give me a list of people who are presently founders and have held the position of founder more than once.'
              },
              { query: 'Give me a list of people whose current company is in stealth.' },
              {
                query:
                  'List all companies in Fintech theme that raised a round in the last 12 months.'
              }
            ]
          }
        ];

  const [expanded, setExpanded] = useState([]);

  const handleSuggestedQueryClick = useCallback(
    (e, item) => {
      const updatedScreenerData = screenerData.map((screenerTab) =>
        screenerTab.entityType === selectedScreenerTab &&
        screenerTab.filterType === selectedTabFilterType
          ? { ...screenerTab, queryText: item }
          : screenerTab
      );
      dispatch(setScreenerData(updatedScreenerData));
    },
    [dispatch, screenerData, selectedScreenerTab, selectedTabFilterType]
  );

  return (
    <>
      <div>
        <List
          disablePadding
          style={{
            background: '#FFF',
            border: '1px solid #E4EBFC',
            borderRadius: '8px',
            minWidth: '300px'
          }}
        >
          <Typography className={classes.queriesHeading}>Pre Defined Queries</Typography>
          {queryData.map((item) => (
            <React.Fragment key={item.queryId}>
              <ListItemButton
                disableRipple
                style={{ borderBottom: '1px solid #F0F0F0' }}
                className={
                  expanded.includes(item.queryId)
                    ? classes.liItemBtSelected
                    : classes.liItemBtUnSelected
                }
                onClick={() => {
                  // setExpanded(
                  //   (prevExpanded) =>
                  //     prevExpanded.includes(item.queryId)
                  //       ? prevExpanded.filter((id) => id !== item.queryId) // Collapse
                  //       : [...prevExpanded, item.queryId] // Expand
                  // );
                  setExpanded(
                    (prevExpanded) =>
                      prevExpanded.includes(item.queryId)
                        ? prevExpanded.filter((id) => id !== item.queryId) // Collapse
                        : [item.queryId] // Expand
                  );
                }}
              >
                <ExpandMoreIcon
                  className={`${classes.expandIcon} ${
                    expanded.includes(item.queryId)
                      ? classes.expandIconOpen
                      : classes.expandIconClosed
                  }`}
                />
                <Typography
                  className={
                    expanded.includes(item.queryId)
                      ? classes.liItemSelectedTxt
                      : classes.liItemUnSelectedTxt
                  }
                >
                  {item.queryName}
                </Typography>
              </ListItemButton>

              <Collapse in={expanded.includes(item.queryId)} timeout="auto" unmountOnExit>
                <List disablePadding>
                  {item.queries.map((queryItem, index) => (
                    <ListItemButton
                      onClick={(e) => handleSuggestedQueryClick(e, queryItem.query)}
                      key={index}
                      style={{ borderBottom: '1px solid #F0F0F0', width: '300px' }}
                    >
                      <Typography className={classes.liItemTxt}>{queryItem.query}</Typography>
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            </React.Fragment>
          ))}
        </List>
      </div>
    </>
  );
};

export default DefinedScreener;
