import Header from './components/shared/header/header';
import Footer from './components/shared/footer';
import 'font-awesome/css/font-awesome.min.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { useEffect } from 'react';
// import { ThemeProvider, createTheme, makeStyles } from '@material-ui/core/styles';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { v4 as uuidv4 } from 'uuid';
import AuthProvider, { AuthContext, AuthStatus } from './components/auth/contexts/authContext';
import { AskBotProvider } from './components/shared/askBot/askBotContext';
import { axiosInterceptor } from './services/interceptor';
import { useNavigate } from 'react-router-dom';
import { useState, useContext } from 'react';
import './App.css';
import { setUuID } from './components/shared/action';
import { useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';
import CustomRoutes from './routes/route';
import { handleSubDomain } from './components/utils/subDomainUtil';
import SuccessToast from './components/atoms/successToast';
import ErrorToast from './components/atoms/errorToast';
import { useSelector } from 'react-redux';
import LoadingPopover from './components/shared/loadingPopover/loadingPopover';
import InfoToast from './components/atoms/infoToast';
import BodyComponent from './components/shared/mainBody';

// const useStyles = makeStyles((theme) => ({
//   right: {
//     [theme.breakpoints.down('sm')]: {
//       display: 'none'
//     }
//   },
//   sideBar: {
//     [theme.breakpoints.down('sm')]: {
//       position: 'absolute',
//       zIndex: 99,
//       left: '-16px',
//       top: '138px'
//     },
//     background: '#F7F9FD',
//     'border-right': '1px solid #E4EBFC',
//     [theme.breakpoints.up('sm')]: {
//       display: 'none'
//     }
//   },
//   CollapseIcon: {
//     'z-index': 999,
//     position: 'absolute',
//     'border-radius': '15px',
//     background: '#fff',
//     width: '32px',
//     height: '22px',
//     '& svg': {
//       top: '2px',
//       position: 'relative',
//       left: '3px',
//       height: '80%'
//     },
//     cursor: 'pointer',
//     [theme.breakpoints.up('sm')]: {
//       display: 'none',
//       top: '87px',
//       left: '30px'
//     },
//     [theme.breakpoints.down('sm')]: {
//       top: '127px',
//       left: '-8px'
//     },
//     'box-shadow': '0px 5px 6px #004CFF33',
//     border: '1px solid #E3EBFD'
//   },
//   activeIcon: {
//     color: '#004CFF'
//   },
//   overlay: {
//     [theme.breakpoints.down('sm')]: {
//       position: 'fixed',
//       width: '100%',
//       height: '100%',
//       top: 0,
//       left: 0,
//       right: 0,
//       bottom: 0,
//       'background-color': 'rgb(160, 160, 160, 0.34)',
//       'z-index': 2,
//       cursor: 'pointer'
//     }
//   },
//   gridWidth: {
//     [theme.breakpoints.down('sm')]: {
//       width: '100%'
//     },
//     [theme.breakpoints.up('sm')]: {
//       width: '93.6%'
//     }
//   },
//   sideBarWidth: {
//     [theme.breakpoints.up('sm')]: {
//       width: 'calc(100% - 93.6%)'
//     },
//     'border-right': '1px solid #E4EBFC'
//   },
//   showDesktop: {
//     [theme.breakpoints.up('sm')]: {
//       display: 'block'
//     }
//   },
//   hideDesktop: {
//     [theme.breakpoints.up('sm')]: {
//       display: 'none'
//     }
//   }
// }));

const enableV1Nav = process.env.REACT_APP_ENABLE_V1_NAVIGATION === 'true';

const theme = createTheme({
  sideBarBackground: {
    secondary: {
      main: '#F7F9FD'
    }
  },
  typography: {
    fontFamily: 'Satoshi'
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          scrollbarColor: '#6b6b6b #2b2b2b',
          '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            backgroundColor: '#2b2b2b'
          },
          '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
            borderRadius: 8,
            backgroundColor: '#6b6b6b',
            minHeight: 24,
            border: '3px solid #2b2b2b'
          },
          '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
            backgroundColor: '#959595'
          },
          '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
            backgroundColor: '#959595'
          },
          '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#959595'
          },
          '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
            backgroundColor: '#2b2b2b'
          }
        }
      }
    }
  }
});

const sessionId = uuidv4();

function NavigateFunctionComponent() {
  let navigate = useNavigate();
  let auth = useContext(AuthContext);
  const [ran, setRan] = useState(false);

  if (!ran) {
    axiosInterceptor(auth, navigate);
    setRan(true);
  }
  return <></>;
}

const disableWhiteLabel = process.env.REACT_APP_DISABLE_WHITE_LABEL;
function App() {
  const dispatch = useDispatch();
  const { authStatus } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const whiteLabelCheck = async () => {
      if (disableWhiteLabel != 'true') {
        const signedIn = authStatus == AuthStatus.SignedIn;
        await handleSubDomain(signedIn);
      }
      setLoading(false);
    };
    whiteLabelCheck();
    dispatch(setUuID(sessionId));
  }, [sessionId]);

  const [prevRoute, setPrevRoute] = useState('/home');
  // const classes = useStyles();
  const globalErrorMessage = useSelector((state) => state.tokenReducer.globalErrorMessage);
  const globalSuccessMessage = useSelector((state) => state.tokenReducer.globalSuccessMessage);
  const globalInfoMessage = useSelector((state) => state.tokenReducer.globalInfoMessage);
  const globalLoading = useSelector((state) => state.tokenReducer.globalLoading);

  return (
    !loading && (
      <ThemeProvider theme={theme}>
        <Router>
          <AuthProvider>
            <AskBotProvider>
              <NavigateFunctionComponent />
              <ErrorToast errorMessage={globalErrorMessage} />
              <SuccessToast successMessage={globalSuccessMessage} />
              <InfoToast infoMessage={globalInfoMessage} />
              <LoadingPopover open={globalLoading} />
              <Header headerConfig={{ setPrevRoute }} />
              {enableV1Nav ? (
                <Grid container>
                  <Grid item sm={12} xs={12}>
                    <CustomRoutes prevRoute={prevRoute}></CustomRoutes>
                  </Grid>
                </Grid>
              ) : (
                <BodyComponent prevRoute={prevRoute} />
              )}
              <Footer />
            </AskBotProvider>
          </AuthProvider>
        </Router>
      </ThemeProvider>
    )
  );
}

export default App;
