import { getApi } from '../../../../../services/token';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AutocompleteComponent from '../../../../atoms/multiSearchableAutocomplete';
import { useDebounce } from '../../../../../hooks/useDebounce';
import { entitesFilterToDisplayMapTheme } from '../../../../../constants/tearSheet/tearSheet';
import { createKeyForSearch } from '../../../../screener/helper';
import { useSelector, useDispatch } from 'react-redux';
import { setThemeSearchData } from '../../../../../redux/pages/common/theme/action';

const isDefaultOption = (option) => {
  return (
    option.entity_type === 'No options' &&
    option.entity_name === 'No options' &&
    option.entity_id === 'custom_entity_id' &&
    option.logo === ''
  );
};

const Theme = ({
  getReports,
  updateParamsInURL,
  navigate,
  location,
  COMPANY_API_URL,
  queryParamName,
  selectedItem,
  setSelectedItem,
  // activeEntityType,
  query_definition_type,
  setOpenThemeDialog,
  getSimilarEntities,
  onEditTheme,
  onDeleteTheme,
  closeCompThemeSearch,
  setCloseCompThemeSearch,
  // loading,
  setLoading
}) => {
  const dispatch = useDispatch();
  const isSingleSelect = true;
  // const [companies, setCompanies] = useState([]);
  // const [isModified, setIsModified] = useState(true);
  const [inputValue, setInputValue] = useState('');
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [selectedTab, setSelectedTab] = useState('');
  const [selectValueFromQuery, setSelectValueFromQuery] = useState(false);
  const debouncedInputValue = useDebounce(inputValue, 500);
  const selectedItems = selectedItem;
  const currentLocation = useLocation();
  const [listLoading, setListLoading] = useState(false);
  const [chipsData, setChipsData] = useState([]);
  const initialLoadRef = useRef(true);

  const storeOptions = useSelector((state) => state.themeReducer.data);

  useEffect(() => {
    return () => {
      setSelectedItem(null);
    };
  }, []);

  useEffect(() => {
    if (selectedTab !== query_definition_type) {
      setSelectedTab(query_definition_type || 'pre_defined');
    }
  }, [query_definition_type]);

  const resetListLoading = () => {
    if (listLoading) setListLoading(false);
  };

  const fetchThemesAndChips = async (searchText, pageNumber, definition_type) => {
    const queryParams = new URLSearchParams({
      entity_types: 'theme,sub_theme',
      page_size: 500,
      page_number: pageNumber,
      search_text: searchText || '',
      definition_type: definition_type || selectedTab || 'pre_defined'
    });

    const currentKey = createKeyForSearch(queryParams);
    const storeOptionsData = storeOptions[currentKey];

    let newOptions = [];
    let totalRecords = 0;
    let chipsData = [];

    if (storeOptionsData) {
      newOptions = storeOptionsData.newOptions || [];
      totalRecords = storeOptionsData.total_records || 0;
      chipsData = storeOptionsData.chipsData || [];
    } else {
      const fetchThemes = getApi(`${COMPANY_API_URL}/v1/entity/search?${queryParams.toString()}`);

      let fetchSimilarEntities =
        pageNumber === 1 ? getSimilarEntities(searchText, 'theme') : Promise.resolve(null);

      const [response, chipsDataRecords] = await Promise.all([fetchThemes, fetchSimilarEntities]);

      newOptions = response.data?.data?.items || [];
      totalRecords = response.data?.data?.metadata?.total_records || 0;
      chipsData = chipsDataRecords || [];

      dispatch(
        setThemeSearchData({
          key: currentKey,
          data: {
            newOptions,
            total_records: totalRecords,
            chipsData
          }
        })
      );
    }

    return {
      newOptions,
      totalRecords,
      chipsData
    };
  };

  // Reusable API function for fetching entities
  const fetchData = async (searchText = '', pageNumber = 1, definition_type = null) => {
    const searchParams = new URLSearchParams(currentLocation.search);
    const currentEntityType = searchParams.get('entity_type');
    if (currentEntityType == 'theme') {
      try {
        setLoading(true);
        const { newOptions, chipsData, totalRecords } = await fetchThemesAndChips(
          searchText,
          pageNumber,
          definition_type
        );

        if (pageNumber === 1) {
          setChipsData(chipsData || []);
          setData(newOptions);
        } else {
          setData((prev) => [...prev, ...newOptions]);
        }

        const newData = pageNumber === 1 ? newOptions : [...data, ...newOptions];

        setHasMore(newData.length < totalRecords);
        setLoading(false);
        resetListLoading();
      } catch (error) {
        setLoading(false);
        console.error('Fetching data failed', error);
        resetListLoading();
      }
    }
  };

  const onAutoSelectUrlQuerySearch = async () => {
    const searchParams = new URLSearchParams(currentLocation.search);
    if (initialLoadRef.current) {
      const selectedEntityName = searchParams.get(queryParamName);
      const definition_type = searchParams.get('definition_type');

      if (selectedEntityName || definition_type) {
        await fetchData(
          selectedEntityName || debouncedInputValue,
          page,
          definition_type || selectedTab
        );
      }
      if (definition_type) setSelectedTab(definition_type);
      if (selectedEntityName) {
        setInputValue(selectedEntityName);
        setSelectValueFromQuery(true);
      }
      initialLoadRef.current = false;
    }
  };

  useEffect(() => {
    if (selectValueFromQuery) {
      const searchParams = new URLSearchParams(currentLocation.search);
      const selectedEntityName = searchParams.get(queryParamName);

      if (selectedEntityName && page === 1 && data.length > 0) {
        setSelectedItem(data[0]);
        getReports(data[0]);
      }
      setSelectValueFromQuery(false);
    }
  }, [selectValueFromQuery]);

  // Load preselected entity from URL when page loads
  useEffect(() => {
    onAutoSelectUrlQuerySearch();
  }, [currentLocation.search]);

  const onInputChange = (event, newValue) => {
    if (!newValue) {
      updateParamsInURL({
        navigate,
        location,
        removeParams: [queryParamName, 'report_id']
      });
    }
  };

  // Fetch data when user types in the input field
  useEffect(() => {
    const searchParams = new URLSearchParams(currentLocation.search);
    const selectedEntityName = searchParams.get(queryParamName);
    const definition_type = searchParams.get('definition_type');
    if (!initialLoadRef.current || (!selectedEntityName && !definition_type)) {
      fetchData(debouncedInputValue, page);
    }
  }, [debouncedInputValue, page, currentLocation.search]);

  useEffect(() => {
    const searchParams = new URLSearchParams(currentLocation.search);
    const selectedEntityName = searchParams.get(queryParamName);
    const definition_type = searchParams.get('definition_type');
    if (inputValue !== '' && !selectedEntityName && !definition_type) {
      setPage(1);
      setData([]);
    }
  }, [debouncedInputValue, currentLocation.search]);

  const onSelectedItems = async (selectedOption) => {
    // setIsModified(false);

    if (selectedOption && isDefaultOption(selectedOption)) {
      return;
    }

    setSelectedItem(selectedOption);

    if (
      selectedOption?.entity_id &&
      (!selectedItem || selectedItem.entity_id !== selectedOption.entity_id)
    ) {
      updateParamsInURL({
        navigate,
        location,
        addParams: { [queryParamName]: selectedOption.entity_name },
        removeParams: ['report_id']
      });

      await getReports(selectedOption);
    } else if (selectedOption?.entity_name) {
      updateParamsInURL({
        navigate,
        location,
        addParams: { [queryParamName]: selectedOption.entity_name },
        removeParams: ['report_id']
      });
    }
  };

  const onSelectedTabOption = (value) => {
    setData([]);
    setPage(1);
    if (value) {
      if (value === 'pre_defined' || value === 'user_defined') {
        setSelectedTab(value);
        setSelectedItem(null);
        setInputValue('');
        updateParamsInURL({
          navigate,
          location,
          addParams: { definition_type: value },
          removeParams: ['search']
        });
        setListLoading(true);
      } else if (value === 'add-theme') {
        setOpenThemeDialog(true);
      }
    }
  };

  useEffect(() => {
    if (closeCompThemeSearch) {
      setCloseCompThemeSearch(false);
      fetchData(debouncedInputValue);
    }
  }, [closeCompThemeSearch]);

  const themeOptions = {
    tabOptions: entitesFilterToDisplayMapTheme,
    selectedTab,
    onSelectedTabOption,
    onEditTheme,
    onDeleteTheme
  };

  return (
    <>
      {/* {JSON.stringify(data[0], undefined, 1)} */}
      <AutocompleteComponent
        data={data}
        hasMore={hasMore}
        setInputValue={setInputValue}
        onInputChange={onInputChange}
        page={page}
        setPage={setPage}
        selectedItems={selectedItems}
        setSelectedItems={onSelectedItems}
        property={{ key: 'entity_id', value: 'entity_name', type: 'entity_type' }}
        isSingleSelect={isSingleSelect}
        label="Search"
        placeholder="Search"
        disabled={false}
        {...themeOptions}
        showChips={true}
        loading={listLoading}
        chipsData={chipsData}
      />
    </>
  );
};

export default Theme;
